import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';

const PanelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    a {
        transition: 0.2s ease-in-out;
        text-decoration: none;
        img {
            transition: 0.2s ease-out;
            transform: scale(1);
        }
        p {
            margin: 8px 0 32px 0;
            transition: 0.2s ease-in-out;
        }
        &:hover {
            p {
                color: ${colors.gray4};
            }
            img {
                transition: 0.2s ease-in-out;
                transform: scale(1.1);
            }
        }
    }
    p {
        font-weight: 600;
        font-size: 20px;
        line-height: 144%;
    }
    div {
        border-radius: 15px;
        width: 343px;
        height: 347px;
        img {
            border-radius: 15px;
            width: 343px;
            height: 347px;
            transform: scale(1);
        }
    }
    @media ${device.tablet} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;

        div {
            margin: 0px 8px;
            width: 303px;
            height: 347px;
            img {
                width: 303px;
                height: 347px;
            }
        }
    }
    @media ${device.laptop} {
        gap: 8px;
        max-width: 1094px;
        margin: 72px auto;
        div {
            width: 343px;
            height: 347px;
            img {
                width: 343px;
                height: 347px;
            }
        }
    }
`;

export const Panels = ({ children }: any) => {
    return <PanelsContainer>{children}</PanelsContainer>;
};
